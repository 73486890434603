<template>
  <main class="h-100" v-if="selectedAthlete">
    <div class="container">
      <input type='file' style="display: none;" ref="avatarUploader" accept="image/png, image/jpeg" @change="avatarSelected"/>
      <div class="d-none d-lg-flex mt-2">
        <country-flag :country='selectedAthlete.country' :rounded='true' size="big" class="me-2 my-auto"/>
        <div class="avatar-container" @click="changeAvatar">
          <img v-if="avatar" :src="avatar" alt="" class="me-3 img img-thumbnail img-avatar" @error="setDefaultAvatar">
          <img v-else src="@/assets/avatar.png" alt="" class="me-3 img img-thumbnail img-avatar">
          <div class="middle">
            <div class="text-muted clickable">Upload</div>
          </div>
        </div>
        <div class="">
          <h1 style="margin:0;">{{ selectedAthlete.lastName }},</h1>
          <h1 style="margin:0;">{{ selectedAthlete.firstName }}</h1>
        </div>
      </div>
      <div class="d-flex d-lg-none mt-4">
        <div class="avatar-container" @click="changeAvatar">
          <img v-if="avatar" :src="avatar" alt="" class="me-3 img img-thumbnail img-avatar" style="height: 40px; width: auto;" @error="setDefaultAvatar">
          <img v-else src="@/assets/avatar.png" alt="" class="me-3 img img-thumbnail img-avatar" style="height: 40px; width: auto;">
          <div class="middle">
            <div class="text-muted clickable">Upload</div>
          </div>
      </div>
        <h1>{{ selectedAthlete.lastName }}, {{ selectedAthlete.firstName }}</h1>
        <country-flag :country='selectedAthlete.country' :rounded='true'/>
      </div>
      <hr class="" />
      <div class="" style="overflow-y: auto">
        <div class="d-flex justify-content-around">
          <div>
            <h4>Tournament Summary Data (Mock)</h4>
            <div class="d-flex">
              <div class="d-flex ">
                <div class="d-flex flex-column text-end">
                  <div class="fw-bold">12</div>
                  <div class="fw-bold">2.4</div>
                  <div class="fw-bold">24</div>
                  <div class="fw-bold">24</div>
                  <div class="fw-bold">10</div>
                  <div class="fw-bold">0.30</div>
                </div>
                <div class="ms-2 d-flex flex-column">
                  <div>Contacts/game</div>
                  <div>Kicks/game</div>
                  <div>Game Load</div>
                  <div>Defensive Score</div>
                  <div>Offensive Score</div>
                  <div>Mean Length</div>
                </div>
              </div>
              <ArenaMap :height="200"/>
            </div>
          </div>
          <div class="">
            <h4>Training Summary Data (Mock)</h4>
            <div class="d-flex">
              <div class="d-flex ">
                <div class="d-flex flex-column text-end">
                  <div class="fw-bold">12</div>
                  <div class="fw-bold">2.4</div>
                  <div class="fw-bold">24</div>
                  <div class="fw-bold">24</div>
                  <div class="fw-bold">10</div>
                  <div class="fw-bold">0.30</div>
                </div>
                <div class="ms-2 d-flex flex-column">
                  <div>Contacts/game</div>
                  <div>Kicks/game</div>
                  <div>Game Load</div>
                  <div>Defensive Score</div>
                  <div>Offensive Score</div>
                  <div>Mean Length</div>
                </div>
              </div>
              <ArenaMap :height="200"/>
            </div>
          </div>
        </div>
        <AthleteViewpoints ref="viewpoints" />
        <!-- <AthleteMatches ref="matches" /> -->
        <AthleteTasks ref="tasks" />

        <hr/>
       
      </div>
    </div>
  </main>
  <main class="h-100 w-100" v-else>
    <div v-if="errorLoading" class="h-100 w-100 d-flex justify-content-center">
      <div class="text-center my-auto"> Unable to load athlete: {{ errorLoading }}</div>
    </div>
    <div v-else class="container">
      Loading
    </div>
  </main>
</template>
<script>

import { mapGetters } from 'vuex';
import api from "../api";

import { BlobClient, BlockBlobClient } from "@azure/storage-blob";
import ArenaMap from "../components/ArenaMap.vue";

export default {
  mixins: {  },
  components: {
    AthleteMatches: () => import("@/components/Athlete/AthleteMatches"),
    AthleteViewpoints: () => import("@/components/Athlete/AthleteViewpoints"),
    AthleteTasks: () => import("@/components/Athlete/AthleteTasks"),
    ArenaMap
},
  data() {
      return {
        errorLoading: null,
        avatar: null,          
      }
  },
  // Synchronous
  created() {
    this.$store.dispatch('selectAthlete', this.$route.params.id);    
    this.$store.commit('UPDATE_SITE_CONFIG', { key: "isSidebarHidden", value: true })

  },
  async mounted() {
    console.log("Athlete mounted");

    try { 
      console.log(`Requesting athlete ID: ${this.$route.params.id}`)
      await this.$store.dispatch('getAthlete', this.$route.params.id);
      this.$store.dispatch('selectAthlete', this.$route.params.id);    
      console.log("Setting avatar", this.selectedAthlete.avatar)
      this.avatar = this.selectedAthlete.avatar;


      await this.$nextTick();
     } catch (e) {
       console.error(e);
      this.errorLoading = e.message;
    }
  },
  async destroyed() {
    console.log("Athlete destroyed");
  },
  computed: {
    ...mapGetters(['selectedAthlete']),

    matches() {
      return this.$refs.matches.$data.matches || [];
      return []
    },
  },
  watch: {
  },
  methods: {
    async changeAvatar() {
      // Open up a file selector.
      console.log("Changing avatar");
      this.$refs.avatarUploader.click();
    },
    async avatarSelected() {
      try { 
        const file = event.target.files[0];
        console.log(`Got file: ${file.name}`);

        const { sasUrl } = (await api.post(`/Users/me/upload-avatar`, {
          fileName: file.name,
          mimeType: file.type,
        })).data;

        console.log(`Got sas url: ${sasUrl}`, sasUrl);

        this.Swal.showLoading();
        const blockBlobClient = new BlockBlobClient(sasUrl);
        await blockBlobClient.uploadData(file, {
          blobHTTPHeaders: {
            blobContentType: this.viewpointData.mimeType
          }
        });
      
        this.avatar = sasUrl;
        const { resource } = (await api.patch(`/Athletes/${this.selectedAthlete.id}`, { avatar: sasUrl })).data; // this is SO nice.
        this.$store.commit('UPDATE_ATHLETE', resource);
        this.Swal.close();
      } catch (e) {
        console.error(e.stack);
        return this.Swal.fire({ title: "Uh-oh", text: e.message, icon: "error" });
      }
    },
    async setDefaultAvatar() {
      this.avatar = null;
    },
  }
}
</script>

<style scoped>
.avatar-container {
  position: relative;
  /* width: 50%; */
}

.img-avatar {
  opacity: 1;
  display: block;
  /* width: 100%; */
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;

  height: 90px;
  width: 90px;
  object-fit: contain;
}

@media (max-width: 576px) {
  .img-avatar {
    height: 40px;
    width: 40px;
  } 

}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  
}

.avatar-container:hover .img-avatar {
  opacity: 0.3;
}

.avatar-container:hover .middle {
  opacity: 1;
}
</style>